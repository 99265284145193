import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/netthandel/src/cart/HeaderCart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SanityAssetImage"] */ "/vercel/path0/apps/netthandel/src/cms/images/SanityAssetImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/components/Header/HeaderLinkBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/components/Header/LinkGroupSideMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuNavigation","MenuTop"] */ "/vercel/path0/apps/netthandel/src/components/Header/MenuState.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SkipToContentLink"] */ "/vercel/path0/apps/netthandel/src/components/Header/skipToContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchBar"] */ "/vercel/path0/apps/netthandel/src/search/SearchBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UniverseThemeProvider"] */ "/vercel/path0/apps/netthandel/src/theming/UniverseTheme.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/Carousel/useCarousel.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/Modal/Modal.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/Table/TableRows.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/Tooltip/Tooltip.js");
