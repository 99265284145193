"use client";
import Link from "next/link";

export const SkipToContentLink = () => {
  return (
    <div className="fixed top-0 left-0 bg-black text-white">
      <Link href="#hovedinnhold" className="sr-only focus:not-sr-only">
        Hopp til hovedinnhold
      </Link>
    </div>
  );
};
